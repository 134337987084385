import { DateFilters } from "../screens/compliance/care-coordinator/SWComplianceList";

// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275
function makeEnum<T extends { [index: string]: U }, U extends string>(x: T) {
    return x;
}

export interface Generic {
    [key: string]: string[] | string | undefined
}

export const PatientSessionStatus: { [key: string]: string } = {
    scheduled: 'scheduled',
    started: 'started',
    inProgress: 'in-progress',
    inProgressUnderscored: 'in_progress',
    finished: 'finished',
    canceled: 'canceled',
    rescheduled: 'rescheduled',
    noShow: 'no_show'
}

export type PatientSessionStatusType = (typeof PatientSessionStatus)[keyof typeof PatientSessionStatus]

export const AppointmentStatus: { [key: string]: string } = makeEnum({
    ...PatientSessionStatus,
    ready: "ready",
    expired: 'expired',
    rejoin: 'rejoin',
    unknown: ''
})

export type AppointmentStatus = (typeof AppointmentStatus)[keyof typeof AppointmentStatus]

export interface IDebug {
    message: string,
    statusCode: number
}

export interface IAccount {
    id: string
    email: string
    firstName: string
    lastName: string
    gender: string
    role: string
    token: string
    error?: string
    permissions: string[]
    isSOAPEnabled?: boolean
}

export interface IPhysicianAccount {
    id: string
    email: string
    phone: string
    slug: string
    zipCode: string
    name: string
    role: string
    canMARefer: boolean
    jwt: string
    error?: string
    qrCodeLink: string
    screenerLink: string
    address: string
    passwordChangeStatus?: string
    permissions: string[]
    roleType: string
    isTwoFactorAuthenticationEnabled?: boolean
    isSOAPEnabled?: boolean
}

export interface IPracticeLocation {
    id: string
    name: string
    email: string
    phone: string
    addressLine: string
    city: string
    state: string
    zipCode: string
    country: string
    providerPracticeId: string
    createdAt: string
    updatedAt: string
    isActive: boolean
    isPhoneVerified: boolean
}

export interface IPracticeLocationResponse {
    locations: IPracticeLocation[],
    count: number
}

export interface ISendEmail {
    email: string
    code: string
    providerId: string
    isCodeSent?: boolean
}

export interface AuthState {
    token: string
    email: string
    role: string
    accountId: string
    practiceLocationId?: string
}

export enum LanguageCode {
    en_us = 'en_us',
    es = 'es',
    hi = 'hi',
    gu = 'gu',
    pl = 'pl',
    ru = 'ru'
}

export const LanguageCodeDescription: { [key in keyof typeof LanguageCode]: string } = {
    en_us: "English",
    es: "Spanish",
    hi: "Hindi",
    gu: "Gujarati",
    pl: "Polish",
    ru: "Russian"
}

export interface ICommon {
    value: string | undefined;
    label: string;
}

export interface ISession {
    appointmentId: string
    scheduledDate: string
    sessionType: string;
    appointmentStatus: AppointmentStatus
}

export interface IPatientSession {
    id: string
    scheduledDate: string
    sessionType: string;
    status: AppointmentStatus
    updatedAt: string
}

interface IPatientStatus {
    status: string
    provider: {
        firstName: string
        lastName: string
    }
}

export interface ITherapistProspect {
    active: boolean
    createdAt: string
    id: string
    prospectId: string
    psychiatristId: string
    sourcePatientId: string
    practiceId: string
    status: string
    therapistId: string
    updatedAt: string
    intakeNotes: [
        { status: string }
    ]
    therapist: {
        firstName: string
        lastName: string
    }
}

export interface IReferredPatientsDetails extends IPotentialReferralPatientBase {
    zipCode: string;
    patientStatus: patient_status
    referredDate: string
    referredCallStatus: ReferredPatientCallStatus
    screenerUserPractices: IScreenerUserPractice[],
    patientStatuses: IPatientStatus[],
    patientSession: [{
        scheduledDate: Date,
        status: PatientSessionStatusType,
        sessionType: keyof typeof SessionTypes
    }],
    therapistProspect: ITherapistProspect[]
}

export interface IProspectBasicDetails {
    id: string
    firstName: string
    lastName: string
    gender: string
    preferredLanguage: LanguageCode
    dob: Date
    referredByPracticeId: string
    referredByPhysicianId: string
    referredByPracticeName: string
    referredByPracticeType: string
    referredByPhysicianFirstName: string
    referredByPhysicianLastName: string
    sourcePatientId: string
    updatedAt: Date
    createdAt: Date
    additionalInfo: IAdditionalInfo;
    referralDetails: ProspectReferralDetails
    appointmentId: string
    scheduledDate: string
    sessionType: string
    phoneNumber: string
    zipCode: string | null
    intakeTargetDate?: Date | null
    intakeSessions: ISession[]
    patientSessions?: IPatientSession[]
    status: patient_status
    dischargeDate: string | undefined
    patientStatuses: any
    therapistProspects: {
        id: string
        createdAt: Date
    }[] | null
}

export interface IProspect extends IProspectBasicDetails {
    patient?: IPatient
    summary?: ISummary
}

export interface IReferredPatient {
    id: string
    firstName: string
    lastName: string
    gender: string
    preferredLanguage: LanguageCode
    dob: Date
    referredByPracticeId: string
    referredByPhysicianId: string
    referredByPracticeName: string
    referredByPracticeType: string
    referredByPhysicianFirstName: string
    referredByPhysicianLastName: string
    sourcePatientId: string
    additionalInfo: IAdditionalInfo;
    referralDetails: ProspectReferralDetails
    phoneNumber: string
    zipCode: string | null
    updatedAt: Date
    createdAt: Date
}
export interface IPatientWithIntake {
    id: string;
    status: string
    intakeLockedAt: string;
    socialWorkerId: string;
    patientId: string;
    updatedAt: string;
    patientIntakeNotes: IPatientIntakeNotes
    patientProspect: IReferredPatient
    createdAt: string;
}

export interface IPatientInCall {
    id: string
    sourcePatientId: string
    referredByPracticeId: string
    firstName: string
    lastName: string
    dob: Date
    phoneNumber: string
}

export interface ITherapistPatient {
    id: string
    createdAt: Date
    updatedAt: Date
    therapistId: string
    prospectId: string
    status: string | null,
    patientProspect: IProspectBasicDetails
    therapist: ITherapist
}

export interface IScreenerComplianceStats {
    pendingScreenersCount: number
    totalScreenersCount: number
}

export interface IClinicalNotesComplianceStats {
    totalPendingCount: number,
    pendingIntakeCount: number,
    pendingProgressCount: number,
    pendingTreatmentCount: number,
    pendingCatchupCount: number,
    totalNotesCount: number
}

export interface IScreenerPendingPatient {
    patientId: string,
    patientFirstName: string,
    patientLastName: string,
    dob: string,
    preferredLanguage: LanguageCode
    referredByPracticeName: string
    therapistFirstName: string
    therapistLastName: string
}

export interface IScreenerPendingPatients {
    count: number
    patients: IScreenerPendingPatient[]
}

export type DischargedPatients = {
    id: string,
    firstName: string,
    lastName: string,
    dob: Date,
    referredDate: Date,
    practiceName: string,
    dischargedDate: Date,
    referringPhysician: string,
}

export type SearchDischargedPatients = {
    patients: DischargedPatients[],
    recordsCount: number
}

export interface IPotentialReferralPatientsResponse {
    patients: IPotentialReferralPatient[]
    count: number
    statusCount: Record<string, number>
}

export interface PatientCallStatus {
    pending: 'pending'
    no_answer: 'no_answer'
    call_again: 'call_again'
    interested: 'interested'
    not_interested: 'not_interested'
};

export type ReferredPatientCallStatus = {
    pending: 'pending'
    no_answer: 'no_answer'
    call_again: 'call_again'
    scheduled: 'scheduled'
    reschedule: 'reschedule'
    no_show: 'no_show'
    not_accepted: 'not_accepted'
    in_progress: 'in_progress'
};

export type IntakeNotesStatus = {
    pending_psych_review: 'Intake Pending'
    locked: 'Intake Submitted'
}

export type ReferredPatientCallStatusType = keyof ReferredPatientCallStatus

interface IProviderPractice {
    name: string;
    id: string;
    taxId: string;
}

interface IProviderPracticeWithProviderDetails extends IProviderPractice {
    providers: {
        id: string;
        firstName: string;
        lastName: string;
        medicaidId: string;
        npi: string;
    }[]
}

interface IScreenerUserPractice {
    createdAt: string,
    id: string,
    practiceId: string,
    updatedAt: string,
    userId: string,
    providerPractice: IProviderPractice
}

interface IScreenerUserPracticeWithProviderPractice {
    createdAt: string,
    id: string,
    practiceId: string,
    updatedAt: string,
    userId: string,
    providerPractice: IProviderPracticeWithProviderDetails
}

interface IPotentialReferralPatientBase {
    id: string
    firstName: string
    lastName: string
    phoneNumber: string
    contactPhoneNumber: string
    dob: string
    screeningDate: string
    gender: string
    preferredLanguage: LanguageCode
    callStatus: PatientCallStatus
    createdAt: string
    updatedAt: string

}

export interface IPotentialReferralPatient extends IPotentialReferralPatientBase {
    screenerUserPractices: IScreenerUserPractice[]
}

export interface IPotentialReferralPatientBasicDetails extends IPotentialReferralPatientBase {
    zipCode: string;
    screenerUserPractices: IScreenerUserPracticeWithProviderPractice[],
    userScreenerSessions: {
        location: IPracticeLocation
    }[]
}

export interface IPotentialPatientInCall extends Pick<IPotentialReferralPatient, 'contactPhoneNumber' | 'id' | 'screenerUserPractices'> {
}

export interface ILockIntakeResponse {
    prospectId: string
    intakeLockedAt: Date | null,
    intakeTargetDate: Date | null,
    therapistId?: string
}

export interface CallModality {
    phone: 'phone',
    video: 'video'
};

export interface IAppointmentWithProspect {
    id: string
    createdAt: Date
    updatedAt: Date
    therapistId: string
    patientId: string
    status: string
    scheduledDate: string
    durationInMinutes: number
    sessionType: string
    endsAtDate: string
    name: string
    patientProspect: IProspect
}

export interface ITherapist {
    id: string
    firstName: string
    lastName: string
    gender: string
    role: string
    npi: string | null
}

export interface IAppointmentWithTherapist extends IAppointmentWithProspect {
    therapist: ITherapist
    modality: CallModality
}

export interface ITherapistsResponse extends ITherapist {
    email: string
    createdAt: Date
    updatedAt: Date
}

export interface ProspectWithStatus {
    prospectStatusUpdated: boolean,
    therapistAssociated?: boolean,
    notesStatusUpdated: boolean,
    screenerUserStatusUpdated: boolean,
    submittedToPsychiatrist?: boolean
}

export interface IAppointmentSession extends Omit<IAppointmentWithProspect, "createdAt" | "updatedAt" | "endsAtDate" | "patientProspect"> {
    endDate: string
    therpistName: string
}

export interface IAppointment {
    id: string
    createdAt: Date
    updatedAt: Date
    therapistId: string
    patientId: string
    status: string
    scheduledDate: Date
    durationInMinutes: number
    modality: string,
    cancellationReason: string | null
    sourcePatientId: string | null
}

export interface ICreateAppointment {
    scheduledDate: string
    sessionType: keyof typeof SessionTypes
    patientId: string
    therapistId?: string
    modality?: string
    treatmentPlanForFollowup?: string
}

export interface IBackdatedAppointmentCreateResponse {
    session: IAppointment;
    notesId: string;
}

export interface IEditAppointment {
    sessionId: string
    patientId: string
    status: keyof typeof PatientSessionStatus,
    reason?: string
}

export interface ProspectReferralNotes {
    symptoms: { selected: string[], other: string }
    comorbidities: { selected: string[], other: string }
    problems: { selected: string[], other: string }
    diagnosis: { selected: string[], other: string }
    currentMedications: { selected: string[] }
    patientMedicationsHistory: { selected: string[] }
    physicianNotes: string | null
    psychiatricMedication: string | null
    isConsentGiven: string
    allergies?: { selected: string, other: string }
}

export type ReferralNoteDisplayMetadata = {
    label: string
    type: "string" | "multi-select" | "single-select"
}

export const ReferralNotesToDisplayMap: { [key in keyof ProspectReferralNotes]: ReferralNoteDisplayMetadata } = {
    symptoms: { label: "Symptoms", type: "multi-select" },
    comorbidities: { label: "Comorbidities", type: "multi-select" },
    problems: { label: "Problems", type: "multi-select" },
    diagnosis: { label: "Diagnosis", type: "multi-select" },
    currentMedications: { label: "Current Medications", type: "multi-select" },
    patientMedicationsHistory: { label: "Patient Medications History", type: "multi-select" },
    physicianNotes: { label: "Physician Notes", type: "string" },
    psychiatricMedication: { label: "Psychiatric Medication", type: "string" },
    isConsentGiven: { label: "Consent Given", type: "string" },
    allergies: { label: "Allergies", type: "single-select" }
}

export interface ProspectReferralDetails extends ProspectReferralNotes {
    summary: ISummary
    screeners: IPatientScreenerIndex[]
}

export interface ISummary {
    pscyComplete: boolean
    pscComplete: boolean
    aceComplete: boolean
    depressionMFQComplete: boolean
    depressionComplete: boolean
    anxietySCAREDComplete: boolean
    anxietyComplete: boolean
    suicidalComplete: boolean
    questionnaireComplete: boolean
    drugComplete: boolean
    alcoholComplete: boolean
    ptsdComplete: boolean
    depressionScore: number
    depressionLevel: DEPRESSION_LEVEL | null
    depressionPositive: boolean
    anxietyScore: number
    anxietyLevel: ANXIETY_LEVEL | null
    anxietyPositive: boolean
    potentialAnxiety: boolean
    pscyScore: number
    pscyNeverScore: number
    pscySometimesScore: number
    pscyOftenScore: number
    pscySuicidalScore: number
    pscySuicidalPositive: boolean
    pscyFailed: boolean
    potentiallyDepressed: boolean
    potentiallyAnxiety: boolean
    potentiallyADHD: boolean
    potentiallyConductDisorder: boolean
    depressionPositiveMessageText: string
    anxietyPositiveMessageText: string
    depressionNegativeMessageText: string
    anxietyNegativeMessageText: string
    pscyPositiveMessageText: string
    suicidalPositiveMessageText: string
    pscyNegativeMessageText: string
    suicidalNegativeMessageText: string
    anxietyScoreNotAtAll: number
    anxietyScoreSeveralDays: number
    anxietyScoreEveryDay: number
    anxietyScoreHalfTheDays: number
    suicidalPositive: boolean
    questionnaireDrugPositive: boolean
    suicidalScoreYes: number
    suicidalScoreNo: number
    suicidalLevelMessageText: string
    pdfSuicidalLevelMessageText: string
    questionnaireDrugNegativeMessageText: string
    questionnaireDrugPositiveMessageText: string
    drugScore: number
    potentialDrug: boolean
    potentialAlcohol: boolean
    questionnaireAlcoholPositive: boolean
    questionnaireAlcoholNegativeMessageText: string
    questionnaireAlcoholPositiveMessageText: string
    questionnaireAlcoholPositiveV2: boolean
    alcoholScore: number
    questionnairePtsdPositive: boolean
    questionnairePtsdNegativeMessageText: string
    questionnairePtsdPositiveMessageText: string
    ptsdScore: number
    potentialptsd: boolean
    acePositive: boolean
    aceScoreYes: number
    aceLevelMessageText: string
    potentialAce: boolean
    summaryAceLevelMessageText: string
    summaryDepressionPositiveMessageText: string
    summaryAnxietyPositiveMessageText: string
    summaryDepressionNegativeMessageText: string
    summaryAnxietyNegativeMessageText: string
    summarySuicidalPositiveMessageText: string
    summarySuicidalNegativeMessageText: string
    summaryPscPositiveMessageText: string
    summaryPscNegativeMessageText: string
    summaryDepressionMFQPositiveMessageText: string
    summaryDepressionMFQNegativeMessageText: string
    summaryAnxietySCAREDPositiveMessageText: string
    summaryAnxietySCAREDNegativeMessageText: string
    pscScreenerPositive: boolean
    depressionMFQPositive: boolean
    anxietySCAREDPositive: boolean
    potentiallyPscDepressed: boolean
    potentiallyPscAnxiety: boolean
    potentiallyPscADHD: boolean
    potentiallyPscConductDisorder: boolean
    potentiallyPanicked: boolean
    potentiallyGeneralizedAnxietyDisorder: boolean
    potentiallySeparationAnxietyDisorder: boolean
    potentiallySocialAnxietyDisorder: boolean
    potentiallySchoolAvoidance: boolean
    pscNeverScore: number
    pscOftenScore: number
    pscSometimesScore: number
    anxietySCAREDScoreNever: number
    anxietySCAREDScoreSometimes: number
    anxietySCAREDScoreOften: number
    depressionMFQScoreNever: number
    depressionMFQScoreSometimes: number
    depressionMFQScoreOften: number
    anxietySCAREDPositiveMessageText: string
    anxietySCAREDNegativeMessageText: string
    adhdComplete: boolean
    adhdInattentivePositive: boolean
    adhdHyperactivePositive: boolean
    adhdCombinedPositive: boolean
    adhdPositiveMessageText: string
    adhdNegativeMessageText: string
    adhdNeverScore: number
    adhdOftenScore: number
    adhdVeryOftenScore: number
    adhdOccationallyScore: number
    adhdWasOnMedicationScore: number
    adhdWasNotOnMedication: number
    adhdNotSureScore: number
    adhdExcellentScore: number
    adhdAboveAverageScore: number
    adhdAverageScore: number
    adhdProblematicScore: number
    adhdSomewhatProblematicScore: number
    summaryAdhdPositiveMessageText: string
    summaryAdhdNegativeMessageText: string
    potentiallyPredominantlyInattentive: boolean
    potentiallyPredominantlyHyperactive: boolean
    potenttiallyOppositionalDefiantDisorder: boolean
    potentiallyImpairmentInPerformance: boolean
    potentiallyAdhdConductDisorder: boolean
    potentiallyAnxietyDepression: boolean
    phq9Positive: boolean
    phq9Complete: boolean
    phq9ScoreNotAtAll: number
    phq9ScoreSeveralDays: number
    phq9ScoreEveryDay: number
    phq9ScoreHalfTheDays: number
    summaryPhq9PositiveMessageText: string
    phq9PositiveMessageText: string
    summaryPhq9NegativeMessageText: string
    phq9NegativeMessageText: string
    phq9SuicidalPositive: boolean
    potentialPhq9: boolean
    twoToSixMonthComplete: boolean
    nineToFifteenMonthComplete: boolean
    eighteenToThirtyMonthComplete: boolean
    thirtySixToSixtyMonthComplete: boolean
    mChatRComplete: boolean
    potentialDevelopmental: boolean | undefined
    potentialTwoToSixBPSC: boolean
    potentialTwoToSixParentConcern: boolean
    potentialTwoToSixTobacco: boolean
    potentialTwoToSixSubstanceAbuse: boolean
    potentialTwoToSixFoodInsecurity: boolean
    potentialTwoToSixDomesticViolence: boolean
    potentialTwoToSixReadingFreq: boolean
    potentialTwoToSixSelfHarm: boolean
    potentialTwoToSixEmotinalChanges: boolean
    potentialNineToFifteenBPSC: boolean
    potentialNineToFifteenParentConcern: boolean
    potentialNineToFifteenTobacco: boolean
    potentialNineToFifteenSubstanceAbuse: boolean
    potentialNineToFifteenFoodInsecurity: boolean
    potentialNineToFifteenParentalDepression: boolean
    potentialNineToFifteenDomesticViolence: boolean
    potentialNineToFifteenReadingFreq: boolean
    potentialNineToFifteenSelfHarm: boolean
    potentialNineToFifteenEmotinalChanges: boolean
    potentialEighteenToThirtyPPSC: boolean
    potentialEighteenToThirtyPOSI: boolean
    potentialEighteenToThirtyParentConcern: boolean
    potentialEighteenToThirtyTobacco: boolean
    potentialEighteenToThirtySubstanceAbuse: boolean
    potentialEighteenToThirtyFoodInsecurity: boolean
    potentialEighteenToThirtyParentalDepression: boolean
    potentialEighteenToThirtyDomesticViolence: boolean
    potentialEighteenToThirtyReadingFreq: boolean
    potentialThirtySixToSixtyPPSC: boolean
    potentialThirtySixToSixtyParentConcern: boolean
    potentialThirtySixToSixtyTobacco: boolean
    potentialThirtySixToSixtySubstanceAbuse: boolean
    potentialThirtySixToSixtyFoodInsecurity: boolean
    potentialThirtySixToSixtyParentalDepression: boolean
    potentialThirtySixToSixtyDomesticViolence: boolean
    potentialThirtySixToSixtyReadingFreq: boolean
    potentialASD1: boolean
    potentialASD2: boolean
    asdPositive: boolean
    emotionalDepressionPositiveText: string
    emotionalDepressionNegativeText: string
    nineToFifteenEmotionalDepressionPositiveText: string
    developmentalNegativeText: string
    developmentalPositiveText: string
    bpscPositiveText: string
    bpscNegativeText: string
    parentConcernPositiveText: string
    parentConcernNegativeText: string
    tobaccoPositiveText: string
    tobaccoNegativeText: string
    substanceAbusePositiveText: string
    substanceAbuseNegativeText: string
    foodInsecurityPositiveText: string
    foodInsecurityNegativeText: string
    domesticViolencePositiveText: string
    domesticViolenceNegativeText: string
    readingFreqPositiveText: string
    readingFreqNegativeText: string
    selfHarmPositiveText: string
    selfHarmNegativeText: string
    parentalDepressionPositiveText: string
    parentalDepressionNegativeText: string
    ppscPositiveText: string
    ppscNegativeText: string
    posiPositiveText: string
    posiNegativeText: string
    asdMessageText: string
    asdPositiveText: string
    asdNegativeText: string
    aceScore: number
    phq9Score: number
    suicidalScore: number
    adhdScore: number
    asdScore: number
    eighteenToThirtyDevelopmentalScore: number
    eighteenToThirtyPPSCScore: number
    eighteenToThirtyPOSIScore: number
    eighteenToThirtyParentConcernScore: number
    eighteenToThirtyTobaccoScore: number
    eighteenToThirtySubstanceAbuseScore: number
    eighteenToThirtyFoodInsecurityScore: number
    eighteenToThirtyParentalDepressionScore: number
    eighteenToThirtyDomesticViolenceScore: number
    eighteenToThirtyReadingFreqScore: number
    sdohComplete: boolean
    sdohScreenerPostive: boolean
    sdohScreenerSlug: string
    sdohScore: number
    sdohNegativeMessageText: string
    sdohPositiveMessageText: string
    twoToSixDevelopmentalScore: number
    twoToSixBPSCScore: number
    twoToSixParentConcernScore: number
    twoToSixTobaccoScore: number
    twoToSixSubstanceAbuseScore: number
    twoToSixFoodInsecurityScore: number
    twoToSixDomesticViolenceScore: number
    twoToSixReadingFreqScore: number
    twoToSixEmotionalChangesScore: number
    twoToSixSelfHarmScore: number

    nineToFifteenDevelopmentalScore: number
    nineToFifteenBPSCScore: number
    nineToFifteenParentConcernScore: number
    nineToFifteenTobaccoScore: number
    nineToFifteenSubstanceAbuseScore: number
    nineToFifteenFoodInsecurityScore: number
    nineToFifteenParentalDepressionScore: number
    nineToFifteenDomesticViolenceScore: number
    nineToFifteenReadingFreqScore: number
    nineToFifteenEmotionalChangesScore: number
    nineToFifteenSelfHarmScore: number

    thirtySixToSixtyDevelopmentalScore: number
    thirtySixToSixtyPPSCScore: number
    thirtySixToSixtyParentConcernScore: number
    thirtySixToSixtyTobaccoScore: number
    thirtySixToSixtySubstanceAbuseScore: number
    thirtySixToSixtyFoodInsecurityScore: number
    thirtySixToSixtyParentalDepressionScore: number
    thirtySixToSixtyDomesticViolenceScore: number
    thirtySixToSixtyReadingFreqScore: number

    pscScore: number
    depressionMFQScore: number
    anxietySCAREDScore: number

    suicidalLevel: ACE_LEVEL | null
    aceLevel: ACE_LEVEL | null
    adhdLevel: ADHD_LEVEL | null
    phq9Level: PHQ9_LEVEL | null
    twoToSixLevel: EMOTIONAL_LEVEL | null
    nineToFifteenLevel: EMOTIONAL_LEVEL | null
    asdLevel: ACE_LEVEL | null
    drugLevel: DRUG_ALCOHOL_LEVEL_TYPE | null
    ptsdLevel: PTSD_LEVEL_TYPE | null
    alcoholLevel: DRUG_ALCOHOL_LEVEL_TYPE | null
    mChatRScreenerSlug: string
    nineToFifteenMonthScreenerSlug: string
    eighteenToThirtyMonthScreenerSlug: string
    thirtySixToSixtyMonthScreenerSlug: string
    pscyScreenerSlug: string
    pscScreenerSlug: string
    phq9ScreenerSlug: string
    drugScreenerSlug: string
    alcoholScreenerSlug: string
    ptsdScreenerSlug: string
    depressionScreenerSlug: string
    depressionMFQScreenerSlug: string
    anxietyScreenerSlug: string
    anxietySCAREDScreenerSlug: string
    suicidalScreenerSlug: string
    aceScreenerSlug: string
    adhdScreenerSlug: string
    questionnaireScreenerSlug: string
    questionnaireScreenerSlugV2: string
    twoToSixMonthScreenerSlug: string
}

enum DEPRESSION_RESULT {
    Minimal, "Moderately Likely", Likely, "Highly Likely"
}

type DEPRESSION_LEVEL = keyof typeof DEPRESSION_RESULT

enum ANXIETY_RESULT {
    Minimal, Mild, Moderate, Severe
}

type ANXIETY_LEVEL = keyof typeof ANXIETY_RESULT

enum PHQ9_RESULT {
    Minimal, Mild, Moderate, "Moderately Severe", Severe
}

type PHQ9_LEVEL = keyof typeof PHQ9_RESULT

enum DRUG_ALCOHOL_RESULT {
    "No Risk", Risky, Harmful, Severe, 'Low Risk'
}

enum PTSD_RESULT {
    "At Risk", "Probable", "No Risk"
}

export type PTSD_LEVEL_TYPE = keyof typeof PTSD_RESULT

export type DRUG_ALCOHOL_LEVEL_TYPE = keyof typeof DRUG_ALCOHOL_RESULT

enum ADHD_RESULT {
    "Adhd Predominantly Inattentive Subtype",
    "Adhd Predominantly Hyperactive/Impulsive Subtype",
    "Adhd Combined Inattention/Hyperactivity"
}

export type ADHD_LEVEL = keyof typeof ADHD_RESULT

enum ACE_RESULT {
    'Low Risk', 'High Risk', 'Intermediate Risk'
}

export type ACE_LEVEL = keyof typeof ACE_RESULT

enum EMOTIONAL_RESULT {
    "Possible Depression", "Probable Depression"
}

export type EMOTIONAL_LEVEL = keyof typeof EMOTIONAL_RESULT

export interface IDebug {
    message: string,
    statusCode: number
}

export interface IPatientScreenerIndex {
    score: IScreenerScore
    slug: string
    id: string
    isComplete: boolean
    isSuicidal: boolean
    createdAt: string
    order: number
    nextVisitTime: number | null
    userSummaryDetails: string
}

export interface IScreenerScore {
    totalScore: { [key: string]: number }
    conditionsScore: { [key: string]: number }
}

export interface IProspectScreenerResponse {
    questionId: string
    question: string
    answer: string
    answerId: string
    condition?: string
    questionType?: 'multiple_choice' | 'choice'
    weightage?: number | null
}

export interface IPatientIndexItem {
    id: string
    firstName: string
    lastName: string
    dob: string
    therapistId?: string
    sourcePatientId: string
    referredByPracticeId: string
}

export interface IPatientIndex {
    patients: IPatientIndexItem[]
}

export interface IReferenceData {
    result: ReferenceResponseMapping[]
    additionalInfo: IPatientIntakeNotes | any
    notesStatus: NotesStatus
    noteId: string
}

export interface ReferenceResponseMapping {
    key: string,
    label: string | null | undefined | ISelect[],
    data?: [{
        value: string,
        label: string,
        key: string,
        type: string,
        defaultChecked: boolean
    }]
}

export interface IAceScreener {
    aceScreenerSlug: string;
    answeredQuestions: IAnsweredQuestions[];
}

export interface IAnsweredQuestions {
    id: string;
    userId: string;
    userScreenerId: string;
    questionId: string;
    answerId: string;
    screenerAnswer: IScreenerAnswer;
    screenerQuestion: IScreenerQuestion
}

export interface IScreenerAnswer {
    id: string;
    content: string;
    screenerQuestionId: string;
    order: number;
    nextQuestionId: any;
}

export interface IScreenerQuestion {
    id: string;
    content: string;
    slug: string;
    subContent: string;
    type: string;
    screenerId: string;
    mentalConditionId: string;
    order: number;
    screenerCondition: string;
}

export interface IAdditionalInfo {
    zipcode: string
    emergencyContact: IEmergencyContact
}

export interface IEmergencyContact {
    email?: string;
    firstName?: string;
    isLegalGuardian?: boolean;
    lastName?: string;
    phoneNumber?: string
}

export interface IInsuranceInfo {
    insuranceCompany: string;
    typeOfPlan: string;
    insuranceId: string;
    primaryHolderName: string;
}

export interface IImpairment {
    selected: string[];
    other: string
}

export interface ICheckInfo {
    selected: ISelect[] | string[];
}

export interface IRadioOtherInfo {
    selected: string;
    other: string
}


export interface IMentalStatus {
    generalAppearance: IGeneralAppearance;
    motorActivity: ICheckInfo;
    affect: ICheckInfo;
    speech: ISpeech;
    thoughtProcess: IThoughtProcess;
}

export interface IGeneralAppearance {
    hygiene: ICheckInfo;
    grooming: ICheckInfo;
    eyeContact: ICheckInfo;
    behavior: ICheckInfo;
}

export interface ISpeech {
    rate: ICheckInfo;
    fluencyRhythm: ICheckInfo;
    relevance: ICheckInfo;
}

export interface IThoughtProcess {
    delusions: ICheckInfo;
    suicidalThought: ICheckInfo;
    homicidalThought: ICheckInfo;
}

export interface IAddress {
    street: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface IPastYearFamilySituation {
    food: string;
    clothing: string;
    utilities: string;
    childCare: string;
    medicine: string;
    phone: string;
    other: string;
}

export interface IAdditionalQuestions {
    pastYearSpentInJail: string;
    refugee: string;
    physicallyAndEmotionallySafe: string;
    afraidOfYourPartner: string;
}

export interface IHOQuestion {
    content: string;
    description: string
}

export interface IPriorCurrentInfo {
    content: string;
    name: string;
    contactNo: string;
}

export interface IPsychiatricHospitalizations {
    content: string;
    subContent: string;
    description: string;
}

export interface IPsychotropicMeds {
    currentPhysician: string[];
    priorPhysician: string[];
    selected: string;
    description: string;
}

export interface IBehavior {
    home: string;
    social: string;
}


export interface IMentalStatusExam {
    key: string;
    header: string;
    questions?: IQuestion[];
}

export interface IQuestion {
    key?: string;
    qLabel?: string;
    className?: string;
    type?: string;
    fields?: IField[];
    subQuestions?: ISubQuestion[];
}

export interface ISubQuestion {
    key?: string;
    qLabel?: string;
    className?: string;
    type?: string;
    fields?: IField[];
}

export interface IField {
    value?: string;
    key?: string;
    label?: string;
    checked?: boolean;
}

export interface ISelect {
    label: string;
    value: string;
}

export interface IPatientIntakeNotes {
    emergencyInfo?: IEmergencyContact;
    insuranceInfo?: IInsuranceInfo;
    symptoms?: IImpairment;
    problems?: IImpairment;
    physicianNotes?: string;
    isCareVerbalConsent?: string;
    mentalStatus?: IMentalStatus;
    religion?: string;
    ethnicity?: string;
    sourceOfIncome?: string;
    language?: string;
    currentAddress?: IAddress;
    noOfFamilyMembers?: string;
    noOfFamilyMembersOther?: string;
    losingHouse?: string;
    education?: string;
    currentEmployment?: string;
    mainInsurance?: string;
    incomeOfFamily?: string;
    pastYearFamilySituation?: IPastYearFamilySituation;
    presentChiefComplaint?: string;
    ethnicityOther?: string;
    retiredMilitary?: string;
    housingSituationToday?: string;
    losingHousing?: string;
    religionOther?: string;
    insurance?: string;
    lastYearFamilyIncome?: string;
    food?: string;
    clothing?: string;
    utilities?: string;
    childCare?: string;
    healthCare?: string;
    phone?: string;
    familyMembersOther?: string;
    social?: string;
    emotional?: string;
    pastYearSpentInJail?: string;
    refugee?: string;
    physicallyAndEmotionallySafe?: string;
    afraidOfYourPartner?: string;
    transportation?: string;
    talkToPeople?: string;
    stressSomeoneFeels?: string;
    additionalQuestions?: IAdditionalQuestions;
    patientMedicationsHistory?: ICheckInfo;
    currentMedications?: ICheckInfo;
    psychiatricIllness?: string;
    psychiatricIllnessOther?: string;
    committedSuicide?: string;
    committedSuicideOther?: string;
    substanceAbuse?: string;
    substanceAbuseOther?: string;
    historyPresentProblem?: string;
    priorCurrentPsychiatristInfo?: string;
    psychiatricHospitalization?: string;
    psychiatricHospitalizationTimeSpan?: string;
    psychiatricHospitalizationTimeSpanOther?: string;
    selfInjuriousBehaviorOther?: string;
    selfInjuriousBehavior?: string;
    historyOfPresentProblem?: string;
    priorCurrentCounselor?: string;
    diagnoses?: ICheckInfo[];
    psychiatristInfo?: IPriorCurrentInfo;
    counselorInfo?: IPriorCurrentInfo;
    psychiatricHospitalizations?: IPsychiatricHospitalizations;
    selfInjuriousBehaviors?: IHOQuestion;
    violenceTowardsOthers?: string;
    violenceTowardsOthersTI?: string;
    priorPhysician?: IPsychotropicMeds;
    behaviors?: IBehavior;
    psychotropicMeds?: IPsychotropicMeds;
    comorbidities?: string[];
    comorbiditiesOther?: string;
    allergies?: IRadioOtherInfo;
    allergiesOther?: string;
    alcohol?: string;
    alcoholOther?: string;
    illicitDrugs?: string;
    illicitDrugsOther?: string;
    developmentalDelays?: string;
    developmentalDelaysOther?: string;
    behaviorAtHome?: string;
    socialBehavior?: string;
    educationalHistory?: string;
    currentEducationalSupport?: string;
    currentEducationalSupportOther?: string;
    hoEducationalSupport?: string;
    hoEducationSupportOther?: string;
    fightsSuspensions?: string;
    fightsSuspensionsOther?: string;
    legalHistory?: string;
    legalHistoryOther?: string;
    lastYearFamilyIncomeOther?: string;
    violenceTowardsOthersOther?: string;
    strengths?: IImpairment;
    limitations?: IImpairment;
    participants?: string;
    diagnosisF1_99?: string[];
    socialDeterminantsOfHealth?: string[];
    isConsentGiven?: string;
    isConsentGivenCollaborativeCare?: string;
    targetDate?: string;
    recommendationNotes?: string;
    documentationTime?: string;
    clinicalSessionTime?: string;
    educationHistory?: string;
    mentalBehavioral?: ISelect[];
    socialDeterminants?: ISelect[];
    mentalStatusOther?: string;
    gender?: string;
    addendums?: any
}

export interface IIntakeFormData {
    createdAt: string;
    id: string;
    intakeLockedAt: string;
    socialWorkerId: string;
    patientId: string;
    updatedAt: string;
    patientIntakeNotes: IPatientIntakeNotes;
}

export interface TreatmentPlanNotes {
    treatmentNotes: {
        [key: string]: [value: string[] | string | number | Object | {
            medicine: string,
            details: string,
            type: string,
            dosage: string
        }]
    }
}

export interface PatientProgressNotes {
    progressNotes: { [key: string]: [value: any] }
    progressNotesId?: string
    notesStatus?: NotesStatus
}

export interface PatientSafetyPlanNotes {
    safetyPlanNotes: { [key: string]: [value: any] }
    notesStatus: NotesStatus
}

export enum SessionTypes {
    FollowUp = 'Follow_Up',
    TreatmentPlan = 'Treatment_Plan',
    Intake = 'Intake',
    SafetyPlan = 'Safety_Plan',
    Catchup = 'Catchup',
    PsychiatristNotes = 'Individual_Psychiatrist_Notes'
}

export enum PatientSessionTypes {
    FollowUp = 'Follow_Up',
    TreatmentPlan = 'Treatment_Plan',
    SafetyPlan = 'Safety_Plan',
    Catchup = 'Catchup',
    PsychiatristNotes = 'Individual_Psychiatrist_Notes'
}

export interface IScreenerCondition {
    slug: string
    conditionDisplayName: string
}

export type PatientJourneyType = 'screeners' | 'notes';

export interface PatientJourney {
    notesId?: string;
    createdAt: string;
    patientId?: string;
    sessionType?: string;
    sessionTypeName?: string
    notesStatus?: NotesStatus
    id?: string,
    screenerId?: string,
    isComplete?: boolean,
    score?: IScreenerScore,
    updatedAt?: string,
    screener?: IScreeners
    isExpired?: boolean
    mentalConditionId?: string | null
    screenerCondition: IScreenerCondition | null
    additionalInfo?: string | null
}

export interface PatientSessionInsights {
    noShowCount: { current: number, previous: number }
    noShowRate: { current: number, previous: number }
}

export interface PatientDailyCheckinInsights {
    averageDailyCheckins: { current: number, previous: number }
    averageWordCount: { current: number, previous: number }
    averagePositivity: { current: number, previous: number }
}

export type FiltersTypes = {
    dobMin?: string | null
    dobMax?: string | null
    practiceId: string | null
    gender: string | null
}

export type Practice = {
    id: string
    name?: string,
    firstName?: string
    lastName?: string
}

export const gender: { male: string, female: string } = {
    male: 'male',
    female: 'female'
};

export type gender = (typeof gender)[keyof typeof gender]

export const patient_status = {
    active: 'active',
    inactive: 'inactive',
    relapse_prevention: "relapse-prevention",
    accepted_to_cocm: 'accepted_to_cocm',
    discharge_from_cocm: 'discharge_from_cocm',
    rejected_from_cocm: 'rejected_from_cocm',
    discharge_reason_patient_got_better: 'discharge_reason_patient_got_better',
    discharge_reason_patient_inactive: 'discharge_reason_patient_inactive',
    discharge_reason_patient_in_need_of_specialized_care: 'discharge_reason_patient_in_need_of_specialized_care',
    discharge_reason_patient_ineligible: 'discharge_reason_patient_ineligible'
};

export type patient_status = (typeof patient_status)[keyof typeof patient_status]

export enum StatusBuckets {
    dischargePatient = 'discharge_patient',
    dischargeReason = 'discharge_reason',
    rejectetpatient = 'rejected_patient',
    dischargeStatus = 'discharge_status',
    sessionStatusToConfirm = 'session_status_to_confirm'
}

export interface IBasicPatient {
    id: string
    firstName: string
    dob: string
    lastName: string
    gender: gender
    zipCode: string
    phoneNumber: string
    contactPhoneNumber: string
    patientStatus: patient_status
    locationName: string
    referringPhysician: string
}

export interface IPatientListItem extends IBasicPatient {
    latestSessionCreatedAt: string | undefined
    canBeReferred: boolean
    atRisk: boolean
}

export interface IPatient extends IBasicPatient {
    isExpired?: boolean
    referNotes: string
    screeners: IPatientScreenerIndex[]
    reasonForVisit: string
    preferredLanguage: LanguageCode
    guardianEmail: string | null
    guardianFirstName: string | null
    guardianLastName: string | null
    guardianPhoneNumber: string | null
    isLegalGuardian: boolean
    referredCallStatus: ReferredPatientCallStatus
}

export interface ICoCMPatient {
    id: string
    firstName: string
    dob: string
    lastName: string
    gender: gender
    screeningDate: string
    updatedAt: string
    createdAt: string
    zipCode: string
    phoneNumber: string
    contactPhoneNumber: string
    patientStatus: patient_status
    preferredLanguage: LanguageCode
    patientStatuses?: [{
        provider: {
            id: string,
            firstName: string,
            lastName: string,
        }
    }]
}

export interface IBillingPatient {
    id: string
    createdAt: Date
    firstName: string
    lastName: string
    phoneNumber: string
    dob: Date
    unit: number
    visit: number
    content: string
    userScreenerOrder: number
    questionOrder: number
    slug: string
    screenerType: string
    cptCode: number
}

export interface DownloadReport {
    link: string
    fileName: string
    presignedURL: string
}

export interface IRefer {
    id: string
    userId: string
    practiceId: string
    referStatus: string
}

export interface ReferenceResponse {
    physicianReportReference: IReportReference
    physicianNotes: IPhysicianNotes | null
}

export interface IReportReference {
    id: string
    diagnosis: { [key: string]: [{ [key: string]: string }] }
    symptoms: { [key: string]: [{ [key: string]: string }] }
    problems: { [key: string]: [{ [key: string]: string }] }
    medications: { [key: string]: [{ [key: string]: string }] }
    comorbidities: { [key: string]: [{ [key: string]: string }] }
}

export interface IPhysicianNotes {
    id: string
    userId: string
    practiceId: string
    symptoms: Generic
    problems: Generic
    diagnosis: Generic
    comorbidities: Generic
    physicianNotes: string | null
    psychiatricMedication: string | null
    currentMedications: Generic
    patientMedicationsHistory: Generic
    isConsentGiven: string
    allergies: Generic
}

export interface IScreenerQuestionAnswer {
    questionId: string
    answer: string
    answerId: string
    question: string
    condition?: string
}

export interface ITherapists {
    id: string
    name: string
    taxId: string
    slug: string
    phone: string
    email: string
    addressLine1: string | null
    addressLine2: string | null
    city: string | null
    state: string | null
    country: string | null
    zipCode: string
    updatedAt: Date
    createdAt: Date
}

export interface IPatientWithSummary {
    patient: IPatient
    summary: ISummary
}

export interface IPatientDetails extends Omit<IPatientWithSummary, 'summary'> {
}

export enum Roles {
    Admin = 'Admin',
    Counsellor = 'Counsellor',
    Care_Coordinator = 'Care_Coordinator',
    Psychiatrist = 'Psychiatrist',
    Physician = 'Physician',
    Medical_Assistant = 'Medical_Assistant'
}

export enum NotesStatus {
    PENDING_PSYCH_REVIEW = 'pending_psych_review',
    LOCKED = 'locked',
    PENDING_SUBMIT_TO_PSYCH = 'pending_submit_to_psych'
}

export enum ScreenerProviderType {
    physician = 'physician',
    therapist = 'therapist',
    psychiatrist = 'psychiatrist'
};

export interface NotesStatusRequest {
    notesId: string
    patientProspectId: string
    sessionType: SessionTypes
    status: NotesStatus
    screeners?: string[]
    sourcePatientId?: string
    practiceId?: string
}

export type CatchupNotes = {
    catchupNotes: { [key: string]: [value: string[] | string | number | Object] }
}

export interface CatchupNotesResponse extends CatchupNotes {
    catchupNotesId: string,
    notesStatus?: NotesStatus
}

export type PsychiatristNotes = {
    psychiatristNotes: { [key: string]: [value: string[] | string | number | Object] }
}

export interface PsychiatristNotesResponse extends PsychiatristNotes {
    psychiatristNotesId: string,
    notesStatus?: NotesStatus
}

export interface IScreeners {
    content: string;
    nextVisitTime: number | null;
    slug: string;
}

export interface IAssignedScreeners {
    id: string,
    screenerId: string,
    isComplete: boolean,
    score: IScreenerScore,
    createdAt: Date,
    updatedAt: Date,
    screener: IScreeners
    mentalConditionId: string | null
    isExpired?: boolean
    screenerCondition: IScreenerCondition | null
}

export interface ScreenerQuestion {
    id: string
    createdAt: Date
    updatedAt: Date
    content: string
    spanishContent: string | null
    slug: string
    subContent: string | null
    type: string
    screenerId: string
    mentalConditionId: string | null
    order: number
}

export interface ScreenerAnswer {
    id: string
    createdAt: Date
    updatedAt: Date
    content: string
    spanishContent: string | null
    screenerQuestionId: string
    order: number
    slug: string
    nextQuestionId: string | null
}

export interface ScreenerQuestionsWithAnswers extends ScreenerQuestion {
    screenerAnswers: ScreenerAnswer[]
}

export interface CurrentQuestion {
    isScreenerCompleted: boolean
    allScreenerQuestions: ScreenerQuestionsWithAnswers[] | null
}

export type ScreenersSlugs = {
    DRUG: 'fp-drug-above18'
}


export type PatientAccount = {
    id: string
    email: string
    contactPhoneNumber: string | null
    slug: string
    zipCode: string
    name: string
    error?: string
    qrCodeUrl: string
    screenerUrl: string
    address: string
    practiceType: string
    isPhoneVerified: boolean | null
    callStatus: PatientCallStatus
    patientStatus: patient_status
    statusAdditionalInfo: string | null
    referredCallStatus: ReferredPatientCallStatus
}

export type PatientAccountResponse = {
    data: {
        updatedStatus: {
            id: string
        }
    }
}

export enum CallStatus {
    OFFLINE = 1,
    REQUESTED,
    INITIATED,
    READY,
    ACTIVE,
    RINGING,
    ON_CALL,
    REJECTED,
    END_CALL_REQUESTED,
    ENDED,
    FAILED_INITIATION,
    ERRORED
}

export const isCallActive = (callStatus: CallStatus) => {
    return [
        CallStatus.REQUESTED, CallStatus.INITIATED, CallStatus.READY, CallStatus.ACTIVE, CallStatus.READY,
        CallStatus.ON_CALL, CallStatus.RINGING
    ].includes(callStatus)
}

export type IPractice = {
    id: string
    isPhoneVerified: boolean | null
}

export type IPracticeResponse = {
    data: IPractice
}

export type LocationIndexItem = {
    id: string
    name: string
    email: string
    phone: string
    addressLine: string
    city: string
    state: string
    zipCode: string
    country: string
    providerPracticeId: string
    createdAt: Date
    updatedAt: Date
    isActive: boolean
    isPhoneVerified: boolean | null
}

export type LocationIndex = {
    locations: LocationIndexItem[],
    count: number
}

type CursorPagination = {
    pageNumber: number
    recordsPerPage: number
    searchText: string
}

export type LocationIndexRequest = Partial<CursorPagination> & {
    locationId?: string
    status?: boolean
}

export function callIsActive(callStatus: CallStatus) {
    return ![CallStatus.OFFLINE, CallStatus.END_CALL_REQUESTED, CallStatus.ENDED, CallStatus.REJECTED].includes(callStatus)
}

export type FetchPatientsPayload = CursorPagination & {
    isPotentialReferrals: boolean;
}

export type FetchCoCMPatientsPayload = CursorPagination & {}

type CountAndPercentage = {
    count: number,
    percentage: number,
}
export type PatientSummaryResponseType = {
    patientsReferred: CountAndPercentage,
    patientsScreened: number,
    patientsEnrolled: CountAndPercentage,
    locationsCount: number,
    potentialPatients: number
}

export type AggregatePatientSummaryByLocation = {
    location: string | null,
    patientsScreened: number,
    patientsReferred: number,
    patientsEnrolled: number,
}

export type ScreenerCompletionRate = {
    totalScreenersCompletedCount: number,
    totalScreenersCount: number,
    totalPendingScreenersCount: number,
    location: string | null,
    locationId: string | null,
    completionRate?: number,
}

interface IProviderLocation {
    providerId: string;
    locationId: string;
    location: LocationIndexItem;
}

export interface IUserForm {
    id?: string
    firstName: string
    lastName: string
    email: string
    phone: string
    role: string
    isActive?: boolean
    shouldResetAndResendCredentials?: boolean
    canMARefer?: boolean
    npi?: string
    // locationId: string
}

export interface IUser {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    createdAt: Date
    updatedAt: Date
    role: string
    isActive: boolean
    canMARefer: boolean
    npi: string
    // providerLocations: IProviderLocation[]
}

export type IUsers = {
    users: IUser[],
    count: number
}

export type UserIndexRequest = Partial<CursorPagination> & {
    status?: boolean;
    role?: string;
    fetchPhysicians?: boolean;
}

export type SoapNotePatientsFilters = Partial<CursorPagination> & {
    locationId?: string;
    startDate?: string;
    endDate?: string;
}

export type SourcePatient = {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    patientStatus: keyof typeof patient_status,
    contactPhoneNumber: string,
    gender: 'male' | 'female',
    dob: string,
    referredDate: string | null,
    zipCode: string,
    preferredLanguage: string,
    patientStatuses?: [{
        medicalAssistant: {
            id: string,
            firstName: string,
            lastName: string,
        },
        provider: {
            id: string,
            firstName: string,
            lastName: string,
        }
    }],
    therapistProspect: [{
        psychiatrist: {
            id: string
            firstName: string
            lastName: string
            npi: string | null
        }
    }]
}

export interface IPatientMaster extends IReferredPatientsDetails { }

export type PatientMasterIndexRequest = Partial<CursorPagination> & {
    practiceIds?: string[];
    socialWorkerIds?: string[];
}

export type IPatientMasters = {
    prospects: IPatientMaster[],
    count: number
}

export type TransferIntakeIndexRequest = Partial<CursorPagination> & {
    sourcePatientId: string;
    therapistId: string
}

export type TScreeningPendingPatientsRequest = Partial<CursorPagination> & {
    status?: boolean;
    role?: string;
    therapistId?: string
    preferredLanguage?: string;
    practiceIds?: string[]
}

export type TScreeningPendingPatientFiltersType = {
    preferredLanguage?: string | null
}

export type ComplianceSWListRequest = Partial<CursorPagination> & {
    isPendingNotes: boolean
    practiceIds?: string[];
    filters?: DateFilters
}


export type DownloadNotesRequest = {
    patientId: string,
    notesType: SessionTypes,
    sourcePatientId: string
    notesId: string
}

export type TPendingClinicalNotesFiltersType = {
    noteType?: SessionTypes | null
    therapistId?: string
    practiceIds?: string[];
}

export type TPendingClinicalNotesListRequest = Partial<CursorPagination> & {
    noteType?: SessionTypes | null
    therapistId?: string
    practiceIds?: string[];
    filters?: DateFilters
}

export type TPendingClinicalNotesStatsRequest = {
    searchText: string
    therapistId?: string
    practiceIds?: string[];
    filters?: DateFilters
}

export interface IPendingClinicalNote {
    noteId: string,
    patientId: string,
    patientFirstName: string,
    patientLastName: string,
    patientDob: Date,
    noteType: SessionTypes,
    noteCreatedAt: Date,
    noteStatus: any,
    isNoteActive: boolean,
    pendingSinceDays: number,
    referredByPracticeName: string
    therapistFirstName: string
    therapistLastName: string
}

export interface IPendingClinicalNotes {
    count: number
    notes: IPendingClinicalNote[]
}

export interface SWUserScreenerRequest {
    patientId: string,
    userScreenerId: string,
    questionId: string,
    answerId: string
    screenerStatus?: 'finished' | 'in_progress'
}

export interface ISOAPNote{
    id: string;
    createdAt: Date;
    updatedAt: Date;
    locationId: string;
    patientId: string;
    recordingPath: string | null;
    transcriptionPath: string | null;
    soapNotePath: string | null;
    isRawDataDeleted: boolean;
    isSOAPNoteRead: boolean;
    status: string,
    reasonForVisit: string,

}

export interface ISOAPNotePatient extends ISOAPNote {
	patient?: {
		id: string;
		firstName: string;
		lastName: string;
		dob: Date;
	};
	location?: {
		id: string;
		name: string;
		providerPractice?: {
			id: string;
			name: string;
			email: string;
		};
	};
}

export interface ISOAPNoteResponse {
    message: string;
    statusCode: number;
    soapNote: ISOAPNote;
}

export interface ISOAPNoteList {
    soapNoteList: ISOAPNote[],
}

export interface ISOAPNoteListItemResponse {
	message: string;
	statusCode: number;
	soapNoteList: ISOAPNoteList;
}

export type ISOAPNotePatients = {
    SOAPNotePatients: ISOAPNotePatient[],
    count: number
}

export interface ISOAPNoteContentResponse {
	message: string;
	statusCode: number;
	SOAPNoteContent: object
}

export type SWScreenerDataPoints = {
    assignedOn: string
    score: number
    interpretation: string
}

export type SWScreenerInsights = {
    screenerName: string
    maxScore: number
    data: SWScreenerDataPoints[]
}

export type SWScreenerInsightsResponse = {
    swScreenerInsights: SWScreenerInsights[]
}

export interface ISWListWithPendingNotesOrScreeners {
    therapistId: string
    therapistName: string
    pending: number
    total: number
    percentage: number
}

export interface IComplianceSWList {
    swList: ISWListWithPendingNotesOrScreeners[] 
    swListCount: number
    tabCount: Record<string, number>
}

export interface IcdCodesRequest {
    userId: string, 
    cptCode: string, 
    units: number, 
    screeningDate: string
    patientName?: string;
    dob?: string;
}